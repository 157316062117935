<template>
  <div class="page">
    <div class="leftDiv">
      <div class="leftTopDiv title">
        <p><span class="fl">发货操作</span> <span class="pageBtn finger btnSure fr" @click="queryCode">查询</span></p>
      </div>
      <div class="leftTopBtm areaFlex1">
        <i-input type="textarea" placeholder="请输入带入库条形码，多个产品换行显示" v-model="codeStr"></i-input>
      </div>
    </div>
    <div class="rightDiv">
      <div class="rightTopDiv title">产品清单<span class="pageBtn finger btnReset fr" @click="back">返回</span></div>
      <selfTable :productList="listColumns1" :productData="meterList1" :isLoad="isLoad"> </selfTable>
      <div class="rightTopDiv marginTop14 title">待发货明细清单<span class="longTextBtn finger btnReset fr marginLeft20" @click="down">模版导入</span><span class="longTextBtn finger btnReset fr" @click="down">模版下载</span></div>
      <Table :columns="listColumns2" :data="meterList2" border>
        <template slot-scope="{ row, index }" slot="barcode">
          <i-input placeholder="请输入" :value="row.barcode" @on-change="changeIpt($event, 'barcode', index)"></i-input>
        </template>
        <template slot-scope="{ row, index }" slot="batch_no">
          <i-input placeholder="请输入" :value="row.batch_no" @on-change="changeIpt($event, 'batch_no', index)"></i-input>
        </template>
        <template slot-scope="{ row, index }" slot="serial_number">
          <i-input placeholder="请输入" :value="row.serial_number" @on-change="changeIpt($event, 'serial_number', index)"></i-input>
        </template>
        <template slot-scope="{ row, index }" slot="delivery_number">
          <i-input placeholder="请输入" type="number" :value="row.num" @on-change="changeIpt1($event, index, row.product_name, row.model_name)"></i-input>
        </template>
        <template slot-scope="{ row, index }" slot="valid_period">
          <DatePicker placeholder="请选择" type="date" :transfer="true" format="yyyy-MM-dd" :value="row.valid_period" @on-change="timeChange($event, index, 'valid_period')"></DatePicker>
        </template>
        <template slot-scope="{ row, index }" slot="production_date">
          <DatePicker placeholder="请选择" type="date" :transfer="true" format="yyyy-MM-dd" :value="row.production_date" @on-change="timeChange($event, index, 'production_date')"></DatePicker>
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 350px; border-top: none !important" class="clearfix noData" v-if="meterList2.length == 0">
        <img style="margin-top: 30px" src="../../assets/images/tableNoData.png" />
      </div>
      <div style="margin: 25px 0">
        <span class="pageBtn finger btnSure fr" @click="sure">确认</span>
      </div>
    </div>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    审核-->
    <Modal v-model="modal1" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>{{ text }}</p>
        <div>
          <span class="pageBtn finger btnSure" @click="lastPost">确定</span>
          <span class="pageBtn finger btnCancle" @click="modal1 = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import selfTable from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  components: {
    selfTable,
    TipsComponent
  },
  name: 'addMeter',
  data() {
    return {
      // codeStr: '010694218038700417220825108700211891013\n' + '010694218038700417220825108700211891012', // 冠脉雷帕霉素洗脱钴基合金支架系统 G2529
      codeStr: '',
      modal1: false,
      text: '',
      deleteStatus: false,
      postStatus: false,
      isLoad: false,
      meterList1: [],
      listColumns1: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 100
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150
        },
        {
          title: '订单数量',
          key: 'number',
          align: 'center',
          width: 140
        },
        {
          title: '发货用量',
          key: 'showNum',
          align: 'center',
          width: 140
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 140
        },
        {
          title: '操作',
          align: 'center',
          width: 130,
          render: (h, param) => {
            if (param.row.showDo) {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.readyRow(param.row, param.index)
                      }
                    }
                  },
                  '预发货'
                )
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer'
                    }
                  },
                  '-'
                )
              ])
            }
          }
        }
      ],
      deleteRow: '',
      clickIndex: 0,
      meterList2: [],
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          fixed: 'left',
          width: 65
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 120
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120
        },
        {
          title: '条形码',
          slot: 'barcode',
          align: 'center',
          width: 150
        },
        {
          title: '批号',
          slot: 'batch_no',
          align: 'center',
          width: 150
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          width: 150
        },
        {
          title: '数量',
          slot: 'delivery_number',
          align: 'center',
          width: 150
        },
        {
          title: '生产日期',
          slot: 'production_date',
          align: 'center',
          width: 150
        },
        {
          title: '有效期',
          slot: 'valid_period',
          align: 'center',
          width: 150
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 80,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      this.deleteRow = param.row
                      this.clickIndex = param.index
                      this.deleteStatus = true
                    }
                  }
                },
                '删除'
              )
            ])
          }
        }
      ]
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.queryDetails(this.id)
  },
  methods: {
    // 扫码
    queryCode() {
      if (!this.codeStr) {
        this.$Message.warning('请输入条形码再查询')
        return
      }
      let arr = this.codeStr.trim().split('\n')
      this.$http.post(this.$api.productSearchBarcode, { barcode_list: arr }, true).then(res => {
        this.codeStr = ''
        if (res.data.length == 0) {
          this.$Message.warning('清单中暂无匹配数据')
        } else {
          // 处理扫描后直接把产品加入到待发货产品清单中在待发货列表 没在预发货列表
          let arr1 = this.meterList1.map(item => item.product_name + '-' + item.model_name)
          let arr2 = this.meterList2.map(item => item.product_name + '-' + item.model_name + '-' + item.serial_number)
          // 待发货列表为空时的扫码直接出来
          if (this.meterList2.length == 0) {
            for (let i = 0; i < res.data.length; i++) {
              if (arr1.includes(res.data[i].name + '-' + res.data[i].model_name) && !arr2.includes(res.data[i].name + '-' + res.data[i].model_name + '-' + res.data[i].serial_number)) {
                let row = res.data[i]
                row.product_name = res.data[i].name
                row.barcode = res.data[i].bar_code
                row.batch_no = res.data[i].batch_number
                row.production_date = res.data[i].production_date ? res.data[i].production_date : null
                row.valid_period = res.data[i].valid_period ? res.data[i].valid_period : null
                row.serial_number = res.data[i].serial_number
                row.num = 1
                row.hadAdd = true
                row.num = 1
                for (let j = 0; j < this.meterList1.length; j++) {
                  if (res.data[i].name + '-' + res.data[i].model_name == this.meterList1[j].product_name + '-' + this.meterList1[j].model_name) {
                    this.$set(this.meterList1[j], 'showNum', this.meterList1[j].showNum + 1)
                    row.id = this.meterList1[j].id
                  }
                }
                this.$set(this.meterList2, this.meterList2.length, row)
              }
            }
          } else {
            let addArr = []
            for (let i = 0; i < res.data.length; i++) {
              for (let j = 0; j < this.meterList2.length; j++) {
                console.log(!this.meterList2[j].hadAdd, !res.data[i].hadAdd, this.meterList2[j].serial_number != res.data[i].serial_number)
                if (res.data[i].name === this.meterList2[j].product_name && res.data[i].model_name === this.meterList2[j].model_name && !this.meterList2[j].hadAdd && !res.data[i].hadAdd) {
                  this.$set(this.meterList2[j], 'hadAdd', true)
                  res.data[i].hadAdd = true
                  this.meterList2[j].barcode = res.data[i].bar_code
                  this.meterList2[j].batch_no = res.data[i].batch_number
                  this.meterList2[j].production_date = res.data[i].production_date ? res.data[i].production_date : this.meterList2[j].production_date
                  this.meterList2[j].valid_period = res.data[i].valid_period ? res.data[i].valid_period : this.meterList2[j].valid_period
                  this.meterList2[j].serial_number = res.data[i].serial_number
                } else if (res.data[i].name === this.meterList2[j].product_name && res.data[i].model_name === this.meterList2[j].model_name && !this.meterList2[j].hadAdd && !res.data[i].hadAdd && this.meterList2[j].serial_number != res.data[i].serial_number) {
                  let row = res.data[i]
                  this.$set(this.meterList2[j], 'hadAdd', true)
                  res.data[i].hadAdd = true
                  row.hadAdd = true
                  row.product_name = res.data[i].name
                  row.barcode = res.data[i].bar_code
                  row.batch_no = res.data[i].batch_number
                  row.production_date = res.data[i].production_date ? res.data[i].production_date : null
                  row.valid_period = res.data[i].valid_period ? res.data[i].valid_period : null
                  row.serial_number = res.data[i].serial_number
                  row.num = 1
                  addArr.push(row)
                } else if (res.data[i].name === this.meterList2[j].product_name && res.data[i].model_name === this.meterList2[j].model_name && this.meterList2[j].serial_number == res.data[i].serial_number) {
                  res.data[i].hadAdd = true
                }
              }
            }
            for (let i = 0; i < res.data.length; i++) {
              for (let j = 0; j < this.meterList1.length; j++) {
                if (res.data[i].name + '-' + res.data[i].model_name == this.meterList1[j].product_name + '-' + this.meterList1[j].model_name && !res.data[i].hadAdd) {
                  let row = res.data[i]
                  res.data[i].hadAdd = true
                  row.hadAdd = true
                  row.product_name = res.data[i].name
                  row.barcode = res.data[i].bar_code
                  row.batch_no = res.data[i].batch_number
                  row.production_date = res.data[i].production_date ? res.data[i].production_date : null
                  row.valid_period = res.data[i].valid_period ? res.data[i].valid_period : null
                  row.serial_number = res.data[i].serial_number
                  row.num = 1
                  row.id = this.meterList1[j].id
                  this.$set(this.meterList1[j], 'showNum', this.meterList1[j].showNum + 1)
                  addArr.push(row)
                }
              }
            }
            this.meterList2 = [...this.meterList2, ...addArr]
          }
          this.meterList2 = JSON.parse(JSON.stringify(this.meterList2))
          console.log(this.meterList2, 'this.meterList2')
        }
      })
    },
    timeChange(e, index, name) {
      this.$set(this.meterList2[index], name, e)
    },
    changeIpt1(e, index, name, model_name) {
      if (e.target.value * 1 >= 0) {
        this.$set(this.meterList2[index], 'num', e.target.value * 1)
      } else {
        this.$set(this.meterList2[index], 'num', 1)
      }
      let total = 0
      for (let i = 0; i < this.meterList2.length; i++) {
        if (this.meterList2[i].product_name == name && this.meterList2[i].model_name == model_name && this.meterList2[i].num) {
          total = total * 1 + this.meterList2[i].num * 1
        }
      }
      console.log(total)
      for (let i = 0; i < this.meterList1.length; i++) {
        if (this.meterList1[i].product_name == name && this.meterList1[i].model_name == model_name) {
          this.$set(this.meterList1[i], 'showNum', total * 1 + this.meterList1[i].total_delivery_number)
        }
      }
    },
    changeIpt(e, name, index) {
      this.$set(this.meterList2[index], name, e.target.value.trim())
    },
    // 确定删除待发货明细
    sureDelete() {
      this.meterList2.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      for (let i = 0; i < this.meterList1.length; i++) {
        if (this.meterList1[i].id == this.deleteRow.id) {
          this.$set(this.meterList1[i], 'showDo', true)
          this.$set(this.meterList1[i], 'showNum', this.meterList1[i].showNum - this.deleteRow.num)
        }
      }
    },
    // 点击预发货
    readyRow(row, index) {
      console.log(row)
      // this.meterList2.push(row)
      row.num = row.number
      row.barcode = ''
      row.batch_no = ''
      this.$set(this.meterList1[index], 'showNum', this.meterList1[index].showNum + row.number)
      this.$set(this.meterList2, this.meterList2.length, row)
      for (let i = 0; i < this.meterList2.length; i++) {
        if (this.meterList2[i].hadAdd) {
          break
        }
        this.meterList2[i].production_date = this.meterList2[i].production_date ? this.$moment(this.meterList2[i].production_date * 1000).format('YYYY-MM-DD') : ''
        this.meterList2[i].valid_period = this.meterList2[i].valid_period ? this.$moment(this.meterList2[i].valid_period * 1000).format('YYYY-MM-DD') : ''
      }
      this.meterList2 = JSON.parse(JSON.stringify(this.meterList2))
    },
    // 查询详情
    queryDetails(id) {
      this.$http.get(this.$api.pruchaseOrderDetail, { id: id }, true).then(res => {
        this.detailsInfo = res.data
        this.meterList1 = res.data.purchase_order_line
        for (let i = 0; i < this.meterList1.length; i++) {
          this.$set(this.meterList1[i], 'showDo', true) // 是否拥有预发布操作按钮
          this.meterList1[i].total_delivery_number = this.meterList1[i].total_delivery_number ? this.meterList1[i].total_delivery_number : 0
          this.$set(this.meterList1[i], 'showNum', this.meterList1[i].total_delivery_number) // 列表显示num
          if (this.meterList1[i].delivery_number == this.meterList1[i].number) {
            this.$set(this.meterList1[i], 'showDo', false)
          }
        }
      })
    },
    // 模版
    down() {
      this.$Message.warning('功能开发中...')
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    changeSelect(e, name, index) {
      this.$set(this.meterList2[index], name, e)
    },
    sure() {
      if (this.meterList2.length == 0) {
        this.$Message.warning('请添加至少一组待发货清单数据再提交')
        return
      }
      for (let i = 0; i < this.meterList1.length; i++) {
        if (this.meterList1[i].showNum > this.meterList1[i].number) {
          this.$Message.warning(`发货数量大于订单数量`)
          return
        }
      }
      for (let i = 0; i < this.meterList2.length; i++) {
        this.meterList2[i].product_code_id = null
        this.meterList2[i].production_date = this.meterList2[i].production_date ? this.meterList2[i].production_date : null
        this.meterList2[i].valid_period = this.meterList2[i].valid_period ? this.meterList2[i].valid_period : null

        if (!this.meterList2[i].num || !this.meterList2[i].batch_no || !this.meterList2[i].valid_period) {
          this.$Message.warning(`请完善待发货明细清单第${i + 1}组的批号/数量/有效期`)
          return
        }
        if (this.meterList2[i].valid_period) {
          if (new Date().getTime() >= new Date(this.meterList2[i].valid_period).getTime()) {
            this.$Message.warning(`待发货明细清单第${i + 1}组的产品已过期`)
          }
        }
        // if (this.meterList2[i].num + this.meterList2[i].showNum > this.meterList2[i].number) {
        //   this.$Message.warning(`待发货明细清单第${i + 1}组的出库数量超过订单数量`)
        //   return
        // }
        this.meterList2[i].delivery_number = this.meterList2[i].num
      }
      for (let i = 0; i < this.meterList1.length; i++) {
        if (this.meterList1[i].number > this.meterList1[i].showNum) {
          this.text = '出库数量小于订单数量，是否部分发货？'
          this.modal1 = true
          return
        }
      }
      this.text = '是否确认发货？'
      this.modal1 = true
    },
    lastPost() {
      let postFrom = {
        order_number: this.detailsInfo.order_number,
        delivery_product: this.meterList2
      }
      this.$http.post(this.$api.pruchaseOrderDelivery, postFrom, true).then(res => {
        this.$Message.success('提交成功')
        this.$router.go(-1)
      })
    }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  overflow: hidden;
  /deep/ .areaFlex1 {
    flex: 1;
    .ivu-input-wrapper {
      height: 100% !important;
    }
    .ivu-input {
      height: 100% !important;
    }
  }
  .title {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #525b6d;
    line-height: 25px;
  }
  .leftDiv {
    width: 370px;
    border-right: 1px solid #e8eaec;
    padding: 27px 24px 26px 24px;
    display: flex;
    flex-direction: column;
    .leftTopDiv {
      line-height: 36px;
      margin-bottom: 21px;
    }
  }
  .rightDiv {
    padding: 27px 24px 26px 24px;
    flex: 1;
    overflow-y: auto;
    .rightTopDiv {
      height: 36px;
      line-height: 36px;
      margin-bottom: 14px;
    }
    .marginTop14 {
      margin-top: 14px;
    }
  }
}
</style>
